export class Customer {
  id: number;
  title: any;
  name: string;
  surname: string;
  language: any;
  country: number;
  countryName: string;
  dial: any;
  phone: any;
  accomodation: any;
  room: any;
  email: any;
  sendEmailCopy: boolean;
  isSpecialAssistanceRequired: boolean;
  specialAssistanceNote: any;
  bookingQuestion: string;
  bookingAnswer: string;
}
