<div class="cart-container" [ngStyle]="{overflow: bookingToBeRemoved ? 'hidden' : 'initial'}">

  <div class="cart-bookings" *ngIf="cart">

    <div class="cart-booking" *ngFor="let booking of cart.bookings; let i = index">
      <span class="remove-booking ico-close" (click)="bookingToBeRemoved = booking"></span>
      <div class="cart-booking-header">
        <h2>{{booking.event.product.name}} <span class="promocode-mark" *ngIf="booking.attachPromocode"></span></h2>
        <h3 *ngIf="booking.event.product.transport">{{booking.event.product.transport.name}}</h3>
        <p class="departure" *ngIf="!isToHideDate()">Departure date: {{utils.formatDateTime(moment(booking.event.start))}}</p>
        <p class="duration" *ngIf="!isToHideDate()">Duration: {{utils.formatDuration(moment(moment().format('YYYY-MM-DD') + ' ' + booking.event.duration))}}</p>
      </div>
      <div class="cart-booking-items-list">
        <div class="cart-booking-item" *ngFor="let genderPrice of booking.bookingGenderPrices">
          <span *ngIf="genderPrice.quantityToBook > 0">
            {{genderPrice.quantityToBook}} x
            {{genderPrice.audienceTypeDescription ? genderPrice.audienceTypeDescription : 'Tickets'}}
          </span>
        </div>
        <div class="cart-booking-item" *ngFor="let component of booking.bookingComponentsGrouped">
          <span>
            {{component.quantity}} x
            {{component.component.name}} - {{component.componentPrice.name}}
          </span>
        </div>
        <div class="cart-booking-item" *ngFor="let transfer of booking.transport.transfers">
          <span>
            {{transfer.transportDirectionDescription}} -
            {{utils.formatDateTime(moment(transfer.date))}} - {{transfer.stop.name}}
          </span>
        </div>
        <div style="margin-top:26px">
          <span class="cart-booking-amount" *ngIf="!booking.event.bookingQuestion || !booking.customer.bookingAnswer">{{utils.convertValueToCurrency(booking.billingTotal)}}</span>
        </div>
      </div>
      <div class="cart-booking-question" *ngIf="booking.customer.bookingQuestion && booking.customer.bookingAnswer">
        <span>Question:</span>
        <p class="cart-booking-question-title">{{booking.customer.bookingQuestion}}</p>
        <p class="cart-booking-question-subtitle">{{booking.customer.bookingAnswer}}</p>
        <span class="cart-booking-amount">{{utils.convertValueToCurrency(booking.billingTotal)}}</span>
      </div>
      <div class="alert-danger cart-booking-item-error" *ngIf="booking.status === -50" (click)="bookingToBeRemoved = booking">
        <p>No longer available, remove to continue</p>
      </div>
    </div>
  </div>

  <button class="btn btn-primary" (click)="goToHome()" *ngIf="cart">
    Add Another Experience
  </button>

  <button class="btn btn-success" [disabled]="!canGoToPayment()" (click)="goToPayment()" *ngIf="cart">
    Go to Summary
  </button>
</div>
<div class="pop-up-container" *ngIf="bookingToBeRemoved">
  <div class="pop-up-mask"></div>
  <div class="pop-up">
    <p>Remove {{bookingToBeRemoved.event.product.name}}?</p>
    <div class="actions">
      <button class="btn btn-secondary" (click)="bookingToBeRemoved = undefined">No</button>
      <button class="btn btn-primary" (click)="removeBooking()">Yes</button>
    </div>
  </div>
</div>
