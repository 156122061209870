import { environment as defaultEnvironment } from './environment.defaults';

/**
 * Environment stage
 * to run with: npm run stage
 */
export const environment = {
  defaultEnvironment,
  production: false,
  resourceServer: 'https://dev.backend.pluralo.com/api/',
  authenticationServer: 'https://dev.backend.pluralo.com/api/',
  gtagId: '',
  isToHidePromocode: false,
};