import { Injectable } from '@angular/core';
declare let gtag: Function;

// Declare GoogleTagManager dataLayer array to be accessed globally.
declare global { interface Window { dataLayer: any[]; } }
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {}

  // code provided by:
  // https://medium.com/madhash/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
  public eventEmitter(
      eventName: string,
      eventCategory: string,
      eventAction: string,
      eventLabel: string = null,
      eventValue: number = null,
      products: object = null
    ) {
      console.log({products});
      const items = [];
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
        products
      });
      console.log('from eventEmitter window.dataLayer===>>>, ', window.dataLayer );

    }
  public purchaseEmitter(
    // transaction_id: string,
    // value: number,

    // eventName: string,
    // eventCategory: string,
    // eventAction: string,
    // eventLabel: string = null,
    // eventValue: number = null,


    id: string,
    billingTotal: number,
    attachPromocode: number,
    products: Array<object>
          ) {
            gtag('event', 'purchase', {
              // 'transaction_id': transaction_id,
              // 'id': transaction_id,
              // 'affiliation': 'Pluralo Widget',
              // 'price': value.toFixed(2),
              // 'currency': 'EUR',
              // 'tax': 0,
              // 'shipping': 0,
              // items,

              'currencyCode': 'EUR',
              'actionField': {
                'id': id, // id do carrinho
                'affiliation': 'Pluralo Widget',
                'revenue': billingTotal, // billingTotal do carrinho
                'coupon': attachPromocode, // attachPromocode
              },
              products
            });
            console.log('from purchaseEmitter===>>>, ', window.dataLayer );
    }

    // EXAMPLE of purchase:
    // gtag.event('purchase', {
    //   "transaction_id": "24.031608523954162",
    //   "affiliation": "Google online store",
    //   "price": 23.07,
    //   "currency": "EUR",
    //   "tax": 1.24,
    //   "shipping": 0,
    //   "items": [
    //     {
    //       "id": "P12345",
    //       "name": "Android Warhol T-Shirt",
    //       "list_name": "Search Results",
    //       "brand": "Google",
    //       "category": "Apparel/T-Shirts",
    //       "variant": "Black",
    //       "list_position": 1,
    //       "quantity": 2,
    //       "price": "2.0"
    //     },
    //     {
    //       "id": "P67890",
    //       "name": "Flame challenge TShirt",
    //       "list_name": "Search Results",
    //       "brand": "MyBrand",
    //       "category": "Apparel/T-Shirts",
    //       "variant": "Red",
    //       "list_position": 2,
    //       "quantity": 1,
    //       "price": "3.0"
    //     }
    //   ]
    // });
}
