import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BookingComponent } from './booking/booking.component';
import { SummaryComponent } from './summary/summary.component';
import { PaymentComponent } from './payment/payment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PaxComponent } from './pax/pax.component';
import { AddComponent } from './add/add.component';
import { ErrorComponent } from './error/error.component';
import { TransportComponent } from './transport/transport.component';
import { TermsComponent } from './terms/terms.component';
import { QuestionComponent } from './question/question.component';
import { CartComponent } from './cart/cart.component';

const routes: Routes = [
  {
    path: '',
    component: BookingComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: BookingComponent,
    pathMatch: 'full'
  },
  {
    path: 'pax',
    component: PaxComponent,
    pathMatch: 'full'
  },
  {
    path: 'add',
    component: AddComponent,
    pathMatch: 'full'
  },
  {
    path: 'transport',
    component: TransportComponent,
    pathMatch: 'full'
  },
  {
    path: 'summary',
    component: SummaryComponent,
    pathMatch: 'full'
  },
  {
    path: 'question',
    component: QuestionComponent,
    pathMatch: 'full'
  },
  {
    path: 'cart',
    component: CartComponent,
    pathMatch: 'full'
  },
  {
    path: 'payment',
    component: PaymentComponent,
    pathMatch: 'full'
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
    pathMatch: 'full'
  },
  {
    path: 'terms',
    component: TermsComponent,
    pathMatch: 'full'
  },
  {
    path: 'error',
    component: ErrorComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }
