<div class="warning" *ngIf="!hasAvailableQuantity(event, true)">
  <p>
    This activity has <b>{{ event.quantityAvailable }}</b> seats available
  </p>
</div>
<div class="warning" *ngIf="_isToRejectBookingMinMax && (_reachedMaximumPax || _reachedMinimumPax)">
  <p *ngIf="_reachedMaximumPax">
    This activity only allows <b>{{event.maximumPaxPerBooking}}</b> seats per booking.
  </p>
  <p *ngIf="_reachedMinimumPax">
    This activity requires at least <b>{{event.minimumPaxPerBooking}}</b> seats per booking.
  </p>
</div>
<div class="warning pending" *ngIf="!_isToRejectBookingMinMax  && (_reachedMaximumPax || _reachedMinimumPax)">
  <p *ngIf="_reachedMaximumPax">
    This activity only allows <b>{{event.maximumPaxPerBooking}}</b> seats per booking. If the limit is exceeded, the booking will be pending confirmation.
  </p>
  <p  *ngIf="_reachedMinimumPax">
    This activity requires at least <b>{{event.minimumPaxPerBooking}}</b> seats per booking. Otherwise the booking will be pending confirmation.
  </p>
</div>

<div class="event-title">
  <h4>{{ event.product.name }}</h4>
  <p *ngIf="!isToHideDate()">
    {{ utils.formatDateTime(moment(event.start)) }} ({{
      utils.formatDuration(
        moment(moment().format("YYYY-MM-DD") + " " + event.duration)
      )
    }})
  </p>
</div>

<div class="pax">
  <div *ngFor="let eventGenderPrice of eventGenderPrices; let i = index">
    <!-- {{ 'audTypOcc '+eventGenderPrice.audienceTypeOccupancy }}
    {{ 'qtyToBook '+eventGenderPrice.quantityToBook }}
    {{ 'sumEvGP '+sumOfGenderPriceQuantityToBook }} -->
    <div class="pax-item" *ngIf="isGenderPriceVisible(eventGenderPrice)">
      <label>
        <span>
          {{ getGenderPriceLabel(eventGenderPrice) }}
          <span *ngIf="eventGenderPrice.audienceTypeLabel"
            >&ensp;({{ eventGenderPrice.audienceTypeLabel }})</span
          >
        </span>
        <span>{{
          utils.convertValueToCurrency(eventGenderPrice.grossprice)
        }}</span>
      </label>
      <app-counter
        [count]="eventGenderPrice.quantityToBook"
        [max]="
          isUnlimited(event)
            ? undefined
            : eventGenderPrice.maxAvailabilityForCurrentAudienceType
        "
        [reachedLimitOfAvailability]="
          reachedLimitOfAvailability(
            event.quantityAvailable,
            eventGenderPrices,
            i
          ) || !hasAvailableQuantity(event, true)
        "
        [reachedMaximumPax]="
        reachedMaximumPax(
            event.maximumPaxPerBooking,
            event.rejectBookingBasedOnMinMax,
            eventGenderPrices,
            i
          )
        "
        [reachedMinimumPax]="
        reachedMinimumPax(
            event.minimumPaxPerBooking,
            eventGenderPrices,
            i
          ) "
        [isToRejectBookingMinMax]="
        isToRejectBookingMinMax(
            event.rejectBookingBasedOnMinMax
          )
        "
        (onChange)="updateQuantity(i, $event)"
      >
      </app-counter>
    </div>
  </div>
</div>

<div class="total-price">
  <app-loading *ngIf="calculatingPrice"></app-loading>
  <p *ngIf="!calculatingPrice">Total</p>
  <p *ngIf="!calculatingPrice">{{ totalPrice }}</p>
</div>
<div class="submit-container">
  <button
    class="btn"
    (click)="goToSummary()"
    [disabled]="
      !booking ||
      calculatingPrice ||
      totalQuantity === 0 ||
      newQuantityAvailable < 0 ||
      (_reachedMinimumPax && _isToRejectBookingMinMax)
    "
  >
    Next
  </button>
</div>
