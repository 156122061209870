import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../shared/global.service';
import {Event} from '../shared/api/model/event';
import {CartService} from '../shared/cart/cart.service';
import {Router} from '@angular/router';
import {ToasterService} from '../shared/atoms/toaster/toaster.service';
import {StaticUtils} from '../shared/utils/static-utils';
import moment from 'moment';
import {Booking} from '../shared/api/model/booking';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  event: Event;
  booking: Booking;
  utils = StaticUtils;
  moment = moment;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _cartService: CartService,
    private _toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.event = this._globalService.event;
    this.booking = this._globalService.booking;
    this.booking.bookingQuestion = this.event.bookingQuestion;
  }

  async next() {
    try {
      this._globalService.loading = true;
      this._globalService.booking = this.booking;

      await this._cartService.addBookingToCart(this._globalService.booking);

      this._globalService.clearBookingProductAndEvent();

      // await this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
      await this._globalService.skip_my_cart === true ? this._router.navigate(['summary'], { queryParamsHandling: 'merge' }) : this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
    } catch (e) {
      this._toasterService.showError(e);
    } finally {
      this._globalService.loading = false;
    }
  }
}
