import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../shared/global.service';
import moment from 'moment';
import { WidgetService } from '../shared/api/widget/widget.service';
import { StaticUtils } from '../shared/utils/static-utils';
import { BookingShoppingCart } from '../shared/api/model/BookingShoppingCart';
import { CartService } from '../shared/cart/cart.service';
import { Customer } from '../shared/api/model/customer';
import { Location } from '@angular/common';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  moment = moment;
  utils = StaticUtils;
  cart: BookingShoppingCart;
  customer: Customer;
  hasMail: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _cartService: CartService,
    private location: Location) { }

  async ngOnInit() {
    const scid = this._route.snapshot.queryParamMap.get('scid');
    const scguid = this._route.snapshot.queryParamMap.get('scguid');
    
    this._globalService.loading = true;

    if(scid && scguid) {
      // This eleminates query paramns after started after &
      this.location.go(this._router.url.split('&')[0]);

      const result = await this._widgetService.resumeCheckout(scid, scguid).toPromise();

      if (result) {
        this.customer = result.bookingShoppingCart.bookings[0].customer;
        this.cart = result.bookingShoppingCart;
        this.hasMail = true;
      } else {
        this.hasMail = false;
      }

      this._globalService.loading = false;
    } else {
      this.customer = this._globalService.customer;
      setTimeout(async () => {
        this.cart = await this._cartService.getCart();
        this._cartService.clearCart();
      });

      this._globalService.loading = false;
    }
  }

  done() {
    this._globalService.clean();
    this._router.navigate(['../home'], { 
      queryParams: { 
        scid: undefined, 
        scguid: undefined, 
        payment_intent: undefined, 
        payment_intent_client_secret: undefined, 
        redirect_status: undefined, 
      },
      queryParamsHandling: 'merge' 
    });
  }

  getTicketNumbers(): string[] {
    return this.cart && this.cart.bookings ? this.cart.bookings.map(booking => this.getTicketNumber(booking.id)) : [];
  }

  getTicketNumber(id: number): string {
    const repeatValue = 5 - id.toString().length;
    let result = '#';

    if (repeatValue >= 1) {
      result = '#' + '0'.repeat(repeatValue) + id;
    } else {
      result = '#' + id;
    }
    return result;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }
}
