import {Location} from './location';
import {Route} from './route';
import {RouteSchedule} from './route-schedule';

export class TransportIteraction {
  id: number;
  route: Route;
  isCustom: boolean;
  order: number;
  transportDirection: number;  // ['OneWay', 'RoundTrip'];
  transportDirectionDescription: string;  // ['OneWay', 'RoundTrip'];
  date: string;
  stop: Location;
  schedule: RouteSchedule;
  exitStop: Location;
}
