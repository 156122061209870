<div class="thankyou" *ngIf="cart || true">
  <div class="heading">
    <h2>Booking successfully done.</h2>
    <br>
    <p *ngIf="!hasMail">An email was sent!</p>
    <p *ngIf="hasMail">An email was sent to</p>
    <span *ngIf="hasMail">{{customer.email}}</span>
    <p>If you do not receive an email in the next minutes, please confirm your <strong>spam box</strong>.</p>
    <p *ngIf="getTicketNumbers().length > 0">Booking reference: <strong>{{getTicketNumbers().join(', ')}}</strong></p>
  </div>
  <div class="content" *ngIf="cart?.bookings">
    <div class="booking" *ngFor="let booking of cart.bookings">
      <h3>
        <span>{{booking.event.product.name}}</span>
        <span>{{getTicketNumber(booking.id)}}</span>
      </h3>
      <h4 *ngIf="booking.event.product.transport">{{booking.event.product.transport.name}}</h4>
      <p>
        <span *ngIf="!isToHideDate()">Departure date: {{utils.formatDateTime(moment(booking.event.start))}}<br></span>
        <span *ngIf="!isToHideDate()">Duration: {{utils.formatDuration(moment(moment(booking.event.end).diff(moment(booking.event.start))).subtract(1, 'hour'))}}</span>
      </p>
      <p>
        {{booking.event.product.descriptionBrief}}
      </p>
    </div>
    </div>
  <button class="btn done" (click)="done()">Done</button>
</div>
