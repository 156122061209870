<div class="terms-wrapper">
  <div class="text-wrapper">
    <p>Please read carefully the following Terms & Conditions for this purchase.</p>
    <br>
    <p>
      Pluralo (Capitães da Areia,lda) provides a technological reservation service that includes communication and payment for services provided by suppliers.
Pluralo (Capitães da Areia, lda) recommends that suppliers inform customers of its terms and conditions, including cancellation and refund policies. Pluralo (Capitães da Areia, lda) cannot be held responsible for any act of the supplier, (for example: cancellation, lack of refund or any other service that causes any dissatisfaction to the customer).
By purchasing this service, you accept and agree to the service provider's terms and conditions and internal policy rules.
    </p>
    <br>
    <div *ngFor="let booking of bookings">
      <h6>{{ booking.event.supplierName }} Terms & Conditions</h6>
      <p>{{ booking.event.product.name }}</p>
      <br>
      <p [innerHTML]="booking.event.product.descriptionMore"></p>
      <br>
    </div>
  </div>
  <div class="button-wrapper">
    <button class="btn" (click)="accept()">Accept</button>
  </div>
</div>
