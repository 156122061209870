import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";

export interface TreeItem {
  name: string;
  children?: TreeItem[];
}

@Component({
  selector: "app-tree-view",
  templateUrl: "./tree-view.component.html",
  styleUrls: ["./tree-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewComponent implements OnInit {
  @Input() placeholder = "";
  @Input() color: string;
  @Input() enableSearch = false;
  @Output() onChange = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @ViewChild("toggle", { static: false }) toggle: ElementRef;
  @ViewChild("menu", { static: false }) menu: ElementRef;
  @ViewChild("input", { static: false }) input: ElementRef;
  @ViewChild("calendarDropdown", { static: false })
  calendarDropdown: NgbDropdown;
  @Input() items: TreeItem[];
  selectedNode: TreeItem;

  private _options = [];
  private _value: any;
  private _search: string;
  private _open = false;

  originalPlaceholder = "";
  styles: any;
  searchFocusEvent = new EventEmitter<any>();
  filteredOptions = [];

  constructor() {}

  ngOnInit() {
    switch (this.color) {
      case "grey":
        this.styles = {
          "background-color": "#f5f8fa",
        };
        break;
    }
    this.originalPlaceholder = this.placeholder;
    this.filterOptions();
  }

  isOptionsDisabled() {
    return !this._options || (this._options.length == 1 && this._options[0].type === 'product')
  }

  filterOptions() {
    if (!this.enableSearch || this.search === "") {
      this.filteredOptions = this._options;
    } else {
      const searchLower = this.search.toLowerCase();
      function searchInNode(node: any) {
        if (node.label.toLowerCase().indexOf(searchLower) >= 0) {
          return true;
        }
        if (node.children) {
          return node.children.some(searchInNode);
        }
        return false;
      }
      this.filteredOptions = this._options.filter(searchInNode);
    }
  }

  setValue(value: any, dropdown: NgbDropdown) {
    this.value = value;
    dropdown.close();
    this.open = false;
    this.onBlur.emit(true);
  }

  get options(): any[] {
    return this._options;
  }

  @Input("options")
  set options(value: any[]) {
    this._options = value;
    this.search = "";
    if (this.originalPlaceholder !== "") {
      this.placeholder = this.originalPlaceholder;
    }
    this.filterOptions();
  }

  get value(): any {
    return this._value;
  }

  // Add a method to toggle the isOpen property
  toggleDropdown(item: any) {
    item.isExpanded = !item.isExpanded;
  }

  // Modify your value setter to close all dropdowns when a new value is selected
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.search = "";
      this.placeholder = value.label;
      this.onChange.emit(this._value);
      if (this.calendarDropdown) this.calendarDropdown.open();
    }
  }

  get search(): string {
    return this._search ? this._search : "";
  }

  set search(value: string) {
    if (this._search !== value) {
      this._search = value;
      this.filterOptions();
    }
  }

  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this.searchFocusEvent.emit(true);
    this._open = value;
  }

  onSelect(item: TreeItem) {
    this.selectedNode = item;
  }
}
