<div [class]="color + ' input-container'" [ngStyle]="styles">
  <input
    type="text"
    [placeholder]="placeholder"
    (input)="value = $event.target.value"
    (keydown)="onKeydown($event)"
    #input>
  <button *ngIf="!loading && buttonText && buttonText !== ''" (click)="onButtonClick()" [disabled]="value === '' || value === undefined">{{buttonText}}</button>
  <div class="loading-container" *ngIf="loading">
    <app-loading [size]="3"></app-loading>
  </div>
</div>
