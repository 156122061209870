import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  @Input() color: string;
  @Input() placeholder: string;
  @Input() buttonText = '';
  @Input() enterKeyEvent = false;
  @Input() inputEvent = true;
  @Input() loading = true;
  @Output() onChange = new EventEmitter<string>();
  @ViewChild('input', {static: false}) input: ElementRef;
  private _value: string;
  public  styles: object;

  constructor() {}

  ngOnInit() {
    switch (this.color) {
      case 'grey':
        this.styles = {
          'background-color': '#f6f6f6'
        };
        break;
      case 'dark-blue':
        this.styles = {
          'background-color': '#1d1e25',
          'color': '#7d939e',
          'border': '1px solid #262734'
        };
        break;
      default:
        this.styles = {
          'background-color': '#ffffff'
        };
        break;
    }
  }

  onKeydown(event) {
    if (this.enterKeyEvent && event.key === 'Enter') {
      this._value = event.target.value;
      this.onChange.emit(this._value);
    }
  }

  onButtonClick() {
    if (this.buttonText && this.buttonText !== '') {
      this.onChange.emit(this._value);
    }
  }

  get value(): string {
    return this._value;
  }

  @Input('value')
  set value(value: string) {
    if (this._value !== value) {
      this._value = value;
      if (this.input) {
        this.input.nativeElement.value = this._value;
      }
      if (this.inputEvent) {
        this.onChange.emit(this._value);
      }
    }
  }
}
