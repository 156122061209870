import {Component} from './component';
import {ComponentPrice} from './component-price';

export class EventComponent {
  id: number;
  component: Component;
  componentPrice: ComponentPrice;
  quantity: number;
  quantityAvailable: number;
  quantityBooked: number;
}
