import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ResourcesService {
  private resourceServer = environment.resourceServer;

  constructor(private _http: HttpClient) { }

  public get(method: string): Observable<any[]> {
    const headersOptions = {};
    const headers = new HttpHeaders(headersOptions);
    const options = {
      headers: headers
    };
    return this._http.get<any[]>(this.resourceServer + method, options);
  }

}
