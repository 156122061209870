// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // resourceServer: 'https://dns-vm-prod-pluralo.westeurope.cloudapp.azure.com:9300/env_prod_api/api/',
  // authenticationServer: 'https://dns-vm-prod-pluralo.westeurope.cloudapp.azure.com:9300/env_prod_api/',
  // resourceServer: 'https://qa-api-resourceserver-pluralo.azurewebsites.net/api/',
  // authenticationServer: 'https://qa-api-resourceserver-pluralo.azurewebsites.net/'
  // resourceServer: 'https://stage-api-resourceserver-pluralo.azurewebsites.net/api/',
  // authenticationServer: 'https://stage-api-resourceserver-pluralo.azurewebsites.net/api/',
  // resourceServer: 'http://localhost:9000/api/',
  // authenticationServer: 'https://localhost:9000/api/',
  //gtagId: 'UA-183439811-1'
  gtagId: '',
  isToHidePromocode: false,
  // VM-PROD
  // resourceServer: 'https://dns-vm-prod-pluralo.westeurope.cloudapp.azure.com:9300/env_prod_api/api/',
  // authenticationServer: 'https://dns-vm-prod-pluralo.westeurope.cloudapp.azure.com:9300/env_prod_api/'
};
