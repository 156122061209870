<div ngbDropdown class="d-flex"
     [autoClose]="'outside'"
     (openChange)="open = !open"
     #dropdown="ngbDropdown">
  <button type="button" class="btn"
          [ngStyle]="styles"
          [disabled]="!options || options.length === 0"
          ngbDropdownToggle
          #toggle>
    <span *ngIf="!enableSearch || (enableSearch && !open)">{{placeholder}}</span>
    <span *ngIf="options && options.length > 0" class="ico-arrow-bottom"></span>
  </button>
  <input type="text" class="search" autocomplete="off" #input
         appFocus
         [focus]="searchFocusEvent"
         [ngClass]="enableSearch && open ? 'visible' : ''"
         [(ngModel)]="search"
         (click)="$event.stopPropagation()">
  <div ngbDropdownMenu #menu>
    <div class="items" *ngIf="filteredOptions && filteredOptions.length > 0">
      <button type="button" class="dropdown-item"
              *ngFor="let option of filteredOptions"
              (click)="setValue(option, dropdown)">
        {{option.label}}
      </button>
    </div>
    <p *ngIf="!filteredOptions || filteredOptions.length === 0">No options to show</p>
  </div>
</div>
