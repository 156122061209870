import {RouteSchedule} from './route-schedule';
import {Location} from './location';

export class RouteItinerary {
  id: number;
  order: number;
  note: string;
  stop: Location;
  schedules: RouteSchedule[];
}
