<div class="error">
  <div class="heading">
    <h2>Something unexpected happened:</h2>
    <p>{{errorMessage}}</p>
  </div>
  <div class="content">
    <!--<h3>-->
      <!--<span>{{product.name}}</span>-->
      <!--<span>{{ticketNumber}}</span>-->
    <!--</h3>-->
    <!--<h4 *ngIf="product.transport">{{product.transport.name}}</h4>-->
    <!--<p>-->
      <!--Departure date: {{moment(event.start).format('DD.MM.YYYY hh:mm A')}}<br>-->
      <!--Duration: {{moment(moment(event.end).diff(moment(event.start))).format('hh:mm')}}-->
    <!--</p>-->
    <!--<p>-->
      <!--Additional Information:<br>-->
      <!--{{booking.event.product.descriptionBrief}}-->
    <!--</p>-->
  </div>
  <button class="btn done" (click)="done()">Try Again</button>
</div>
