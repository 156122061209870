import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input('background') background = 'transparent';
  @Input('size') size = 5;
  containerStyle = {};
  loaderStyle = {};
  constructor() { }

  ngOnInit() {
    this.containerStyle = {
      'background-color': this.background
    };
    this.loaderStyle = {
      'font-size': this.size + 'px',
      'top': 'calc(50% - ' + (this.size * 2.5) + 'px)'
    };
  }

}
