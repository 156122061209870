import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtmCustomService {

  // Checks if the GTM script has already been injected
  private isGtmScriptInjected(gtmId: string): boolean {
    return !!document.querySelector(`script[src*="gtm.js?id=${gtmId}"]`);
  }

  // Checks if the GA4 script has already been injected
  private isGa4ScriptInjected(ga4Id: string): boolean {
    return !!document.querySelector(`script[src*="gtag/js?id=${ga4Id}"]`);
  }

  // Loads and starts GTM
  public initGtm(gtmId: string) {
    if (!this.isGtmScriptInjected(gtmId)) {
      const gtmInit = document.createElement('script');
      gtmInit.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      
      const deniedConsentScript = document.createElement('script');
      deniedConsentScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
      `;
      document.head.appendChild(gtmInit);
      document.head.appendChild(deniedConsentScript);

    }
  }

  // Load GA4
  public loadAndInitGa4(ga4Id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.isGa4ScriptInjected(ga4Id)) {
        // Define the gtag function if it doesn't exist
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        window.gtag = gtag;
  
        // Initialize the GA4 script using the GTM-like method
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'event': 'gtag.js', 'gtm.start': new Date().getTime()});
          
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s) as HTMLScriptElement,  // Explicitly type as HTMLScriptElement
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          
          //j.async = true;
          j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;    
          d.head.appendChild(j);
        })(window,document,'script','dataLayer', ga4Id);

        const gtaInit = document.createElement('script');
        gtaInit.innerHTML = `
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('set', 'cookie_flags', 'SameSite=None;Secure;Partitioned;');
          gtag('config', '${ga4Id}');
        `;
        
        document.head.appendChild(gtaInit);

        resolve(); // Resolve after GA4 is initialized
      } else {
        // If the script is already loaded, initialize GA4
        if (typeof window.gtag === 'function') {
          window.gtag('config', ga4Id, {
            'cookie_flags': 'SameSite=None;Secure'
          });
        }
        resolve(); // Resolve immediately if already loaded and configured
      }
    });
  }

  // Update consent
  public updateConsent(ga4Id: string) {
    if (typeof window.gtag === 'function') {
      const grantedConsentScript = document.createElement('script');
      grantedConsentScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });
      `;

      document.head.appendChild(grantedConsentScript);

      window.gtag('config', ga4Id, {
        'cookie_flags': 'SameSite=None;Secure'
      });
    }
  }

  // Check if app is inside an iframe
  public isInIframe(): boolean {
    return window.top !== window.self;
  }

  public removeInvalidGtmScripts() {
    // Remove scripts
    this.removeGtmScripts();
  
    // Watcher to see the DOM and remove scripts without ids
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeName === 'SCRIPT') {
              const script = node as HTMLScriptElement;
              const src = script.getAttribute('src');
              if (src && src.includes('https://www.googletagmanager.com/gtm.js?id=') && src.endsWith('=')) {
                script.remove();
              }
            }
          });
        }
      });
    });
  
    observer.observe(document.head, { childList: true, subtree: true });
  }
  
  private removeGtmScripts() {
    const scripts = document.querySelectorAll('script');
  
    scripts.forEach(script => {
      const src = script.getAttribute('src');
  
      // Checks if script has a URL without an ID
      if (src && src.includes('https://www.googletagmanager.com/gtm.js?id=') && src.endsWith('=')) {
        script.remove();
      }
    });
  
    // Remove script injected by the lib 
    const gtmScript = document.getElementById('GTMscript');
    if (gtmScript) {
      gtmScript.remove();
    }
  }
  
  // Remove invalid <noscript> scripts from the body
  public removeInvalidNoscriptTagsAndAddValidOne(gtmId: string) {
    const noscriptTags = document.querySelectorAll('noscript#GTMiframe');

    noscriptTags.forEach(noscriptTag => {
      const iframe = noscriptTag.querySelector('iframe');
      if (iframe && iframe.getAttribute('src') && iframe.getAttribute('src').endsWith('id=')) {
        noscriptTag.remove();
      }
    });

    // Create noscript and iframe to place in the body
    const noscriptElement = document.createElement('noscript');
    noscriptElement.id = "GTMiframe";

    const iframeElement = document.createElement('iframe');
    iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframeElement.height = "0";
    iframeElement.width = "0";
    iframeElement.style.display = "none";
    iframeElement.style.visibility = "hidden";

    noscriptElement.appendChild(iframeElement);
    document.body.prepend(noscriptElement);
  }
}
