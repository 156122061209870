import { Pipe, PipeTransform } from '@angular/core';
import {EventComponent} from './api/model/event-component';

@Pipe({name: 'filterByComponentType', pure: false})

export class FilterByComponentTypePipe implements PipeTransform {
  transform(items: EventComponent[], args: any): EventComponent[] {
    // componentType: 0 is for standard extras and componentType: 1 is for transport
    return items.filter(item => item.component.componentType === args.componentType);
  }
}
