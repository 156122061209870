import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../shared/global.service';
import { Booking } from '../shared/api/model/booking';
import { Subscription } from 'rxjs';
import { WidgetService } from '../shared/api/widget/widget.service';
import { EventComponent } from '../shared/api/model/event-component';
import { Product } from '../shared/api/model/product';
import { Event } from '../shared/api/model/event';
import { Router } from '@angular/router';
import { BookingCalculation } from '../shared/api/model/booking-calculation';
import { StaticUtils } from '../shared/utils/static-utils';
import { ToasterService } from '../shared/atoms/toaster/toaster.service';
import { CartService } from '../shared/cart/cart.service';
import moment from 'moment';

@Component({
  selector: 'app-extras',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  loading = false;
  calculatedPriceSub: Subscription;
  calculatingPrice = false;
  components: EventComponent[];
  product: Product;
  event: Event;
  previousLoadedComponents = false;
  firstLoad = false;
  utils = StaticUtils;
  moment = moment;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _toasterService: ToasterService,
    private _cartService: CartService) { }

  ngOnInit() {
    this._globalService.selectedTransportComponent = undefined

    this.event = this._globalService.event;
    this.product = this._globalService.product;
    if (this._globalService.booking && this._globalService.booking.bookingComponents) {
      this.components = this._globalService.booking.bookingComponents;
      this.previousLoadedComponents = true;

    // reset quantity for components with componentType === 1
    this.resetQuantityOfTransportComponents(this.components);

    } else {
      this.components = [];
      for (const extra of this.event.product.extras) {
        const component = new EventComponent();
        component.id = extra.id;
        component.component = extra.item;
        component.componentPrice = extra.selectedPrice;
        component.quantity = 0;
        component.quantityAvailable = this.getQuantityAvailable(component.id);
        this.components.push(component);
      }
    }
    if (!this._globalService.calculatedPrice) {
      this.updatePrice().then();
    }
  }

  goToTransport() {
    this._globalService.selectedTransportComponent = undefined; // not a transport extra, reset to default
    this._globalService.booking.bookingComponents = this.components;
    this._globalService.userWantsTransport = true;
    this._router.navigate(['transport'], { queryParamsHandling: 'merge' });
  }

  async goToTransportAsExtra(component: EventComponent, totalPax: number ) {
    try {
      // reset quantity
      for (let originalComponent of this.components) {
        if(originalComponent.component.componentType === 1) {
          originalComponent.quantity = 0;
        }
      }

      this._globalService.loadingTransportCalculation = true;
      this._globalService.selectedTransportComponent = component;
      this._globalService.booking.bookingComponents = this.components;
      this._globalService.userWantsTransport = true;
      this._router.navigate(['transport'], { queryParamsHandling: 'merge' });

      await this.updateComponentQuantity(component.id, component.component.id, component.componentPrice.id, totalPax);
      await this.updatePrice();
    } catch (e) {
      this._toasterService.showInfo(e.error.message);
    } finally {
      if (this.firstLoad) {
        this.firstLoad = false;
        this.previousLoadedComponents = false;
      }
      this._globalService.loadingTransportCalculation = false;
      this.calculatingPrice = false;
    }
  }

  async goToSummaryOrQuestion() {
    this._globalService.booking.bookingComponents = this.components;
    this._globalService.userWantsTransport = false;
    if (this.event.bookingQuestionIsActive) {
      await this._router.navigate(['question'], { queryParamsHandling: 'merge' });
    } else {
      try {
        this._globalService.loading = true;
        await this._cartService.addBookingToCart(this.booking);
        this._globalService.clearBookingProductAndEvent();
        // await this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
        await this._globalService.skip_my_cart === true ? this._router.navigate(['summary'], { queryParamsHandling: 'merge' }) : this._router.navigate(['cart'], { queryParamsHandling: 'merge' });
      } catch (e) {
        this._toasterService.showError(e);
      } finally {
        this._globalService.loading = false;
      }
    }
  }

  async updateComponentQuantity(componentId: number, componentComponentId: number, priceId: number, quantity: number) {
    if (!this.firstLoad) {
      if (this.previousLoadedComponents) {
        this.firstLoad = true;
      }

      for (const component of this.components) {
        if (
          component.id === componentId ||
          (component.component.id === componentComponentId && component.componentPrice.id === priceId)
        ) {
          component.quantity = quantity;
          break;
        }
      }

      this.loading = true;
      this._globalService.booking.bookingComponents = this.components;

      try {
        await this.updatePrice();
      } catch (e) {
        this._toasterService.showInfo(e.error.message);
      } finally {
        if (this.firstLoad) {
          this.firstLoad = false;
          this.previousLoadedComponents = false;
        }
        this.loading = false;
        this.calculatingPrice = false;
      }
    }
  }

  updatePrice(): Promise<BookingCalculation | undefined> {
    return new Promise((resolve, reject) => {
      this.calculatedPriceSub = this._widgetService.calculatePrice(this._globalService.booking).subscribe(
        (bookingCalculation: BookingCalculation) => {
          this._globalService.calculatedPrice = bookingCalculation;
          this._globalService.booking.calculationsGUID = bookingCalculation.calculationsGUID;
          resolve(bookingCalculation);
        },
        reject
      );
    });
  }

  isComponentUnlimited(componentId: number): boolean {
    let result = false;
    for (let i = 0; this.event.product.extras.length > i; i++) {
      if (this.event.product.extras[i].id === componentId) {
        result = this.event.product.extras[i].capacityUnlimited;
        break;
      }
    }
    return result;
  }

  getQuantityAvailable(componentId: number): number {
    let result = 0;
    for (let i = 0; this.event.eventComponents.length > i; i++) {
      if (this.event.eventComponents[i].id === componentId) {
        result = this.event.eventComponents[i].quantityAvailable;
        break;
      }
    }
    return result;
  }

  allowTransport(): boolean {
    return this.event.widgetHaveAccessPickups && this._globalService.hasTransport();
  }

  get booking(): Booking {
    return this._globalService.booking;
  }

  /**
   * Checks if the available seats should be greater than the quantity
   *
   * @param {EventComponent} component
   * @return {boolean}
   * @memberof AddComponent
   */
  isLimitExceededThisTransportComponentSeats(component: EventComponent): boolean {
    if(this.isComponentUnlimited(component.id)) return true;
    return this._globalService.totalPax() <= (component.quantityAvailable);
  }

  isTransportComponentSelected(component: EventComponent): boolean {
    return this._globalService.selectedTransportComponent && this._globalService.selectedTransportComponent.id === component.id;
  }

  unselectTransportComponent(component: EventComponent): void {
    this.updateComponentQuantity(component.id, component.component.id, component.componentPrice.id, 0)
    this._globalService.selectedTransportComponent = undefined;
  }

  isLimitExceededOnAllTransportComponentsSeats(components: EventComponent[]): boolean {
    return components
      .filter(component => component.component.componentType === 1)
      .some(component => this.isLimitExceededThisTransportComponentSeats(component));
  }

  checkIfHasMoreThanOneTransportComponent(components: EventComponent[]): boolean {
    const bool = components
      .filter(component => component.component.componentType === 1)
      .filter(component => this.isLimitExceededThisTransportComponentSeats(component))
      .length > 1;
    this._globalService.isMoreThanOneTransportComponent = bool; // set global variable, based on the result of the filter above. To be used in transport component
    return bool; // returning to keep the function running in the template
  }

  isExistsTransportComponent(components: EventComponent[]): boolean {
    return components
      .filter(component => component.component.componentType === 1)
      .some(component => this.isLimitExceededThisTransportComponentSeats(component));
  }

  isSimpleExtra(components: EventComponent[]): boolean {
    return components
      .find(component => component.component.componentType === 0) !== undefined; // not a transport extra, it's a simple extra
  }

  async resetQuantityOfTransportComponents(components: EventComponent[]) {
    components.forEach( (component: { component: { componentType: number; id: number; }; quantity: number; componentPrice: { id: number; }; id: number; }) => {
      if (component.component.componentType === 1) {
        component.quantity = 0;
      }
    });

    this.loading = true;

    try {
      await this.updatePrice();
    } catch (e) {
      this._toasterService.showInfo(e.error.message);
    } finally {
      if (this.firstLoad) {
        this.firstLoad = false;
        this.previousLoadedComponents = false;
      }
      this.loading = false;
      this.calculatingPrice = false;
    }
  }

}
