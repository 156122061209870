<div class="event-title">
  <h4>{{event.product.name}}</h4>
  <p>{{utils.formatDateTime(moment(event.start))}} ({{utils.formatDuration(moment(moment().format('YYYY-MM-DD') + ' ' + event.duration))}})</p>
</div>

<div class="question-container">
  <div class="input-container question-input">
    <label>{{event.bookingQuestion}} <span *ngIf="event.bookingQuestionIsMandatory">*</span></label>
    <div class="input-group">
      <input class="form-control" type="text" autocomplete="off" [(ngModel)]="booking.bookingAnswer" [ngModelOptions]="{standalone: true}" #questionInput>
      {{ questionInput.focus() }}
    </div>
  </div>

  <button class="btn" (click)="next()"
    [disabled]="event.bookingQuestionIsMandatory && (!booking.bookingAnswer || booking.bookingAnswer === '')">
    Next
  </button>
</div>
