import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() placeholder = '';
  @Input() color: string;
  @Input() enableSearch = false;
  @Output() onChange = new EventEmitter<any>();
  @Output() onBlur  = new EventEmitter<any>();
  @ViewChild('toggle', {static: false}) toggle: ElementRef;
  @ViewChild('menu', {static: false}) menu: ElementRef;
  @ViewChild('input', {static: false}) input: ElementRef;

  private _options = [];
  private _value: any;
  private _search: string;
  private _open = false;

  originalPlaceholder = '';
  styles: any;
  searchFocusEvent = new EventEmitter<any>();
  filteredOptions = [];

  constructor() {
  }

  ngOnInit() {
    switch (this.color) {
      case 'grey':
        this.styles = {
          'background-color': '#f5f8fa'
        };
        break;
    }
    this.originalPlaceholder = this.placeholder;
    this.filterOptions();
  }

  filterOptions() {
    if (!this.enableSearch || this.search === '') {
      this.filteredOptions = this._options;
    } else {
      this.filteredOptions = this._options.filter((option) => option.label.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
    }
  }

  setValue(value: any, dropdown: NgbDropdown) {
    this.value = value;
    dropdown.close();
    this.open = false;
    this.onBlur.emit(true);
  }

  get options(): any[] {
    return this._options;
  }

  @Input('options')
  set options(value: any[]) {
    this._options = value;
    this.search = '';
    if (this.originalPlaceholder !== '') {
      this.placeholder = this.originalPlaceholder;
    }
    this.filterOptions();
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.search = '';
      this.placeholder = value.label;
      this.onChange.emit(this._value);
    }
  }

  get search(): string {
    return this._search ? this._search : '';
  }

  set search(value: string) {
    if (this._search !== value) {
      this._search = value;
      this.filterOptions();
    }
  }

  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this.searchFocusEvent.emit(true);
    this._open = value;
  }
}
