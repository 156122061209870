import { Component, OnInit } from "@angular/core";
import { SettingsService } from "./shared/api/resources/settings/settings.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GlobalService } from "./shared/global.service";
import { StripeService } from "ngx-stripe";
import { WidgetService } from "./shared/api/widget/widget.service";
import { ToasterService } from "./shared/atoms/toaster/toaster.service";
import { CartService } from "./shared/cart/cart.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [],
})
export class AppComponent implements OnInit {
  currentUrl = "";
  settingsLoaded = false;
  stripeKeyLoaded = false;
  cartAmount = 0;
  navigationHistory = [];
  backLink = "";
  goingBack = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _settings: SettingsService,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _stripeService: StripeService,
    private _toasterService: ToasterService,
    private gtmService: GoogleTagManagerService,
    private _cartService: CartService
  ) {
    this._router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page_change",
          currentPage: item.url,
        };
        this.gtmService.pushTag(gtmTag).catch(() => {});
        console.log("gtmTag :>> ", gtmTag);
      }
    });
  }

  async ngOnInit() {
    this._route.queryParams.subscribe((param: any) => {
      if (param?.guid) {
        this._globalService.guid = param.guid;
        this._settings.getAllRequests().subscribe(
          () => {},
          (error) => this._toasterService.showError(error.error.message),
          () => (this.settingsLoaded = true)
        );
        this._widgetService.getStripeKey().subscribe(
          (data) => this._stripeService.setKey(data.stripeApiKey),
          (error) => this._toasterService.showError(error.error.message),
          () => (this.stripeKeyLoaded = true)
        );
        this._router.navigate([""], { queryParamsHandling: "merge" });
      }
      
      if (param?.hide_date) {
        this._globalService.hide_date = param.hide_date;
        console.log("hide_date==>", param.hide_date);
      }

      if (param?.event_date) {
        this._globalService.event_date = param.event_date;
        console.log("event_date==>", param.event_date);
      }

      if (param?.skip_my_cart) {
        this._globalService.skip_my_cart = true;
      }

      if (param?.scid && param?.scguid) {
        if (param?.redirect_status === 'succeeded') {
          this._router.navigate(['../thankyou/'], { 
            queryParamsHandling: 'merge' }
          );
        } else if (param?.redirect_status === 'failed'){
          this._globalService.errorMessage = 'Something unexpected happened. Please try again from the begining!';

          this._router.navigate(['../error/'], { 
            queryParams: { 
              goHome: true, 
            },
            queryParamsHandling: 'merge' 
          });
        }
      }
    });

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const path = evt.url.split("?")[0];

        if (!this.goingBack && this.navigationHistory[0] !== this.currentUrl && this.currentUrl !== '/error') {
          this.navigationHistory.unshift(this.currentUrl);
        }

        if (this.goingBack || path === '/error') {
          this.navigationHistory.splice(0, 1);
        } 

        this.backLink = this.navigationHistory[0];
        this.currentUrl = path;
        this.goingBack = false;

        document.getElementsByTagName("main").item(0).scrollTop = 0;
      }
    });

    this._cartService.shoppingCartChange.subscribe(() => {
      this.cartAmount = this._cartService.getCartBookingsAmount();
    });
  }

  preventContextMenu(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  handleBackClick(): void {
    this.goingBack = true;
  }

  public allowNavigation(): boolean {
    const url = this.currentUrl.split("?")[0];
    return (
      !["/", "/home", "/thankyou", "/error"].includes(url) &&
      !this._globalService.processingPayment &&
      !(url === "/cart" && !this._globalService.event)
    );
  }

  goToCart() {
    if (this.cartAmount > 0) {
      this._router.navigate(["cart"], { queryParamsHandling: "merge" }).then();
    }
  }

  get title(): string {
    let title = "";
    switch (this.currentUrl.split("?")[0]) {
      case "/":
        title = "Booking";
        break;
      case "/home":
        title = "Booking";
        break;
      case "/pax":
        title = "Add Pax";
        break;
      case "/add":
        title = "Add";
        break;
      case "/transport":
        title = "Transport";
        break;
      case "/question":
        title = "Question";
        break;
      case "/summary":
        title = "Summary";
        break;
      case "/cart":
        title = "My Cart";
        break;
      case "/payment":
        title = "Payment Details";
        break;
      case "/thankyou":
        title = "Thank You";
        break;
      case "/terms":
        title = "Terms & Conditions";
        break;
      case "/error":
        title = "Error";
        break;
    }
    return title;
  }

  get loading(): boolean {
    return this._globalService.loading;
  }

  public skipMyCart(): any {
    return this._globalService.skip_my_cart;
  }

  public isSkipMyCartAndSummaryRoute() {
    const isSkipMyCart =
      this._globalService.skip_my_cart === true ? true : false;
    const isSummaryRoute = this._router.url.includes("summary");
    return isSummaryRoute && isSkipMyCart === true ? true : false;
  }
}
