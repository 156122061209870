import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../shared/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../shared/cart/cart.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorMessage = '';
  goHome: Boolean | String;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _cartService: CartService,
              private _globalService: GlobalService) { }

  ngOnInit() {
    this.goHome = this._route.snapshot.queryParamMap.get('goHome') || false;
    this.errorMessage = this._globalService.errorMessage || '';
  }

  done() {
    if (this.goHome) {
      this._globalService.clean();
      this._router.navigate(['../home'], {
        queryParams: { 
          goHome: undefined,
          scid: undefined, 
          scguid: undefined, 
          payment_intent: undefined, 
          payment_intent_client_secret: undefined, 
          redirect_status: undefined, 
        },
        queryParamsHandling: 'merge' 
      });
    } else {
      this._router.navigate(['../payment/'], { 
        queryParams: { 
          goHome: undefined,
          scid: undefined, 
          scguid: undefined, 
          payment_intent: undefined, 
          payment_intent_client_secret: undefined, 
          redirect_status: undefined, 
        },
        queryParamsHandling: 'merge' 
      });
    }
  }
}
