<app-loading *ngIf="!initialized"></app-loading>

<p class="no-products" *ngIf="showNoProductsMessage()">
  There are currently no products available.<br />Please try again later.
</p>
<div
  class="categories-and-products-dropdown"
  *ngIf="showCategoriesAndProducts()"
>
  <app-tree-view
    [items]="treeData"
    [placeholder]="getCategoriesAndProductsLabel()"
    [options]="categoriesAndProductsOptions"
    (onChange)="handleCategoryOrProductChange($event.value, $event.type)"
  ></app-tree-view>
</div>
<div class="search-filters" *ngIf="showCategoriesAndProducts()">
  <div class="dates-filters">
    <div>
      <app-date-input
        *ngIf="!isToHideDate()"
        [useNativeMobileInput]="false"
        [minDate]="moment()"
        [startDate]="dateValue.start"
        [endDate]="dateValue.end"
        [disabled]="!(selectedProduct || selectedCategory) || eventDate()"
        [markedDays]="daysWithEvents"
        [notMarkedDaysConfig]="{ color: '#a9a9a9' }"
        [disableNonMarkedDays]="true"
        [asyncNavigation]="true"
        [onNavigationPromise]="daysWithEventsPromise"
        (onChange)="onDateChange($event).then()"
        (onNavigation)="updateDaysWithEvents($event)"
        ngbDropdown
        #calendarDropdown="ngbDropdown"
      >
      </app-date-input>
    </div>
  </div>
</div>
<div class="search-results table-responsive" *ngIf="!showNoProductsMessage()">
  <table class="table" *ngIf="events?.length > 0 && !reloadingEvents">
    <tbody>
      <tr
        *ngFor="let event of events"
        [attr.disabled]="isButtonDisabled(event) ? 'disabled' : null"
        [class.available-to-book]="!isButtonDisabled(event)"
        (click)="goToBook(event).then()"
      >
        <!-- <h1 [class.available-to-book]="moment(event.start).isBefore(moment())">{{moment(event.start).isBefore(moment())}}</h1> -->
        <th scope="row" class="time" *ngIf="!isToHideDate()">
          {{ utils.formatTime(moment(event.start)) }}<br />
        </th>
        <td
          [class]="
            event.eventGenderPrices[0]?.genderPriceType === 1
              ? 'info per-order'
              : 'info'
          "
        >
          <!-- Activity Name -->
          <p
            placement="top"
            class="activity-name"
            [ngbTooltip]="eventName(event)"
            *ngIf="showActivityName(event.product)"
          >
            {{ eventName(event) }}
          </p>

          <!-- Schedule Title -->
          <p
            placement="top"
            class="schedule-title"
            [ngbTooltip]="event.product.widgetGroupScheduleTitle"
            *ngIf="showGroupScheduleTitle(event.product)"
          >
            {{ event.product.widgetGroupScheduleTitle }}
          </p>

          <!-- Resource Name -->
          <p
            placement="top"
            class="resource-name"
            [ngbTooltip]="getEventResourcesDescription(event)"
            *ngIf="showResourceName(event.product)"
          >
            {{ getEventResourcesDescription(event) }}
          </p>

          <!-- Lower and Higher Gross Price -->
          <strong *ngIf="showPriceRange(event.product)"
            ><small
              >{{ eventLowerPrice(event) }} -
              {{ eventHigherPrice(event) }}</small
            ></strong
          >

          <span>
            {{
              (showDuration(event.product)
                ? utils.formatDuration(
                    moment(moment().format("YYYY-MM-DD") + " " + event.duration)
                  )
                : "") +
                (showDuration(event.product) &&
                showTotalAvailability(event.product)
                  ? " - "
                  : "") +
                (showTotalAvailability(event.product)
                  ? getEventAvailabilityDescription(event)
                  : "")
            }}
          </span>
        </td>

        <td class="price">
          {{
            event.eventGenderPrices[0]?.genderPriceType === 1
              ? utils.convertValueToCurrency(
                  event.eventGenderPrices[0].grossprice
                )
              : ""
          }}
        </td>
        <td class="book">
          <button class="btn" [disabled]="isButtonDisabled(event)">BOOK</button>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="text-center" *ngIf="showNoEventsMessage()">
    There are no events for the selected dates.
  </p>
</div>
