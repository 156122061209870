<div class="event-title">
  <h4>{{event.product.name}}</h4>
  <p>{{utils.formatDateTime(moment(event.start))}} ({{utils.formatDuration(moment(moment().format('YYYY-MM-DD') + ' ' + event.duration))}})</p>
</div>

<div class="extras" *ngIf="components.length > 0 || allowTransport() && !isExistsTransportComponent(components)">
  <p *ngIf="components.length > 0 && isSimpleExtra(components)">Do you wish to add extras?</p>
  <!--  componentType: 0 is for standard extras and componentType: 1 is for transport  -->
  <div *ngFor="let component of components | filterByComponentType: { componentType: 0 } ; let i = index" class="extra-container">
    <div class="extra-info">
      <label>{{component.component.name}} - {{component.componentPrice.name}}</label>
      <span>
        {{utils.convertValueToCurrency(component.componentPrice.grossprice)}} - {{isComponentUnlimited(component.id) ? 'Unlimited' : component.quantityAvailable + ' available'}}
      </span>
    </div>
    <div class="extra-counter">
      <app-counter
        [count]="component.quantity"
        [max]="isComponentUnlimited(component.id) ? undefined : component.quantityAvailable"
        (onChange)="updateComponentQuantity(component.id, component.component.id, component.componentPrice.id, $event)">
      </app-counter>
    </div>
    <div class="extra-description">
      <p>{{component.component.description}}</p>
    </div>
  </div>
  <!-- show only if there is no extras of type transport and if this product allow transport -->
  <div *ngIf="allowTransport() && !isExistsTransportComponent(components)" class="need-transport">
    <p><span class="ico-Car"></span>Need transport?</p>
    <div class="answer">
      <button class="btn btn-primary">No</button>
      <button class="btn btn-secondary" (click)="goToTransport()">Yes</button>
    </div>
  </div>
  <!-- show only if there is extras of type transport -->
  <p *ngIf="isLimitExceededOnAllTransportComponentsSeats(components)"><span class="ico-Car mr-2"></span>Need transport?</p>
  <div *ngFor="let component of components | filterByComponentType: { componentType: 1 } ; let i = index" class="extra-container">
    <span *ngIf="checkIfHasMoreThanOneTransportComponent(components)" style="display: none; /* functional phantom here, don't remove */"></span>
    <div *ngIf="isLimitExceededThisTransportComponentSeats(component)">
      <div class="extra-info">
        <label>{{component.component.name}} - {{component.componentPrice.name}}</label>
        <span>
          Price per passenger: {{utils.convertValueToCurrency(component.componentPrice.grossprice)}}
          <br>
          Total price: {{ utils.convertValueToCurrency( _globalService.totalPax() * component.componentPrice.grossprice ) }}
        </span>
      </div>
      <div class="answer ml-2" [ngClass]="isTransportComponentSelected(component) ? 'selected' : ''">
        <button class="btn btn-no" (click)="isTransportComponentSelected(component) ? unselectTransportComponent(component) : null">No</button>
        <button class="btn btn-yes" (click)="goToTransportAsExtra(component, _globalService.totalPax())">Yes</button>
      </div>
      <div class="extra-description">
        <p>{{component.component.description}}</p>
      </div>
    </div>
  </div>
</div>

<div class="total-price">
  <app-loading *ngIf="loading"></app-loading>
  <p *ngIf="!loading">Total</p>
  <p *ngIf="!loading">{{ _globalService.totalPrice }}</p>
</div>

<div class="submit-container">
  <button class="btn" (click)="goToSummaryOrQuestion()" [disabled]="!booking || calculatingPrice">
    {{event && event.bookingQuestionIsActive ? 'Next' : 'Go to Summary'}}
  </button>
</div>
