<div class="widget-container" (contextmenu)="preventContextMenu($event)">
  <header>
    <!-- <h1>{{isSkipMyCartAndSummaryRoute()}}</h1> -->
    <div class="back-btn-container">
      <a [routerLink]="backLink"
         (click)="handleBackClick()"
         queryParamsHandling="merge"
         *ngIf="allowNavigation() && !isSkipMyCartAndSummaryRoute() ">
        <span class="ico-arrow-left"></span>
      </a>
    </div>
    <h1>{{title}}</h1>
    <div *ngIf="isSkipMyCartAndSummaryRoute()" class="cart-container"></div>
    <div *ngIf="!isSkipMyCartAndSummaryRoute()" class="cart-container" [ngClass]="{'disabled': cartAmount === 0}" (click)="goToCart()">
      <span class="cart-amount" *ngIf="cartAmount">{{cartAmount}}</span>
      <span class="fas fa-shopping-cart"></span>
    </div>
  </header>
  <app-loading [background]="'rgba(255,255,255,0.666)'" *ngIf="!stripeKeyLoaded || !settingsLoaded || loading"></app-loading>
  <main>
    <router-outlet *ngIf="settingsLoaded && stripeKeyLoaded"></router-outlet>
  </main>
  <footer>
    <p>We use secure technology to protect your details.</p>
    <img src="../assets/images/logo-pluralo.svg">
  </footer>
</div>
