<div class="date-input-wrapper">
  <input
    class="form-control"
    name="dp"
    [placeholder]="placeholder"
    (click)="d.open()"
    (change)="handleInputChange($event.target.value)"
    ngbDatepicker
    [autoClose]="'outside'"
    [disabled]="disabled"
    [displayMonths]="displayMonths"
    [minDate]="toNgbDate(minDate)"
    [maxDate]="toNgbDate(maxDate)"
    [placement]="position"
    [outsideDays]="'hidden'"
    [navigation]="navigation"
    [dayTemplate]="t"
    [value]="getFormattedInputLabel()"
    (dateSelect)="onDateSelection($event)"
    (navigate)="onNavigate($event)"
    #d="ngbDatepicker"
    *ngIf="insidePopover && !isMobile"
    #datePickerInput
  />

  <input
    class="form-control"
    type="date"
    [disabled]="disabled"
    [value]="
      toMoment(this.fromDate)
        ? toMoment(this.fromDate).format('YYYY-MM-DD')
        : null
    "
    [min]="minDate ? minDate.format('YYYY-MM-DD') : ''"
    [max]="maxDate ? maxDate.format('YYYY-MM-DD') : ''"
    (change)="onMobileDateSelection($event)"
    *ngIf="insidePopover && isMobile"
  />

  <ngb-datepicker
    #dp
    (select)="onDateSelection($event)"
    (navigate)="onNavigate($event)"
    [displayMonths]="displayMonths"
    [minDate]="toNgbDate(minDate)"
    [maxDate]="toNgbDate(maxDate)"
    [navigation]="navigation"
    [dayTemplate]="t"
    outsideDays="hidden"
    *ngIf="!insidePopover"
  >
  </ngb-datepicker>
</div>

<ng-template #t let-date="date" let-disabled="disabled" let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="addRangeClass(date)"
    [class.faded]="addFadedClass(date)"
    [class.disabled]="disabled || (disableNonMarkedDays && !getMarkedDay(date))"
    [style.backgroundColor]="getMarkedDayBgColor(date)"
    [style.color]="getMarkedDayColor(date)"
    [style.fontWeight]="getMarkedDay(date) ? 'bold' : 'normal'"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
    (click)="handleDayClick($event, date)"
  >
    {{ date ? date.day : "1" }}
  </span>
</ng-template>
