import {Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Output() onChange = new EventEmitter<number>();
  @Input() reachedLimitOfAvailability: boolean;
  @Input() reachedMinimumPax: boolean;
  @Input() reachedMaximumPax: boolean;
  @Input() isToRejectBookingMinMax: boolean;
  @Input() max: number;
  @Input() min = 0;
  @Input() count = 0;
  @ViewChild('input', {static: false}) input: ElementRef;

  constructor(private _cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  increment() {
    if ( (this.max === undefined || this.count < this.max) && !this.reachedLimitOfAvailability ) {
      this.count += 1;
      this.onChange.emit(this.count);
    }
  }

  decrement() {
    if (this.count > this.min) {
      this.count -= 1;
      this.onChange.emit(this.count);
    }
  }

  updateValue(value) {
    if (value !== null) {
      const previousAccountValue = this.count.toFixed(0);
      if (this.max !== undefined && value > this.max) {
        this.count = this.max;
      } else if (value < this.min) {
        this.count = this.min;
      } else {
        this.count = value;
      }

      this.input.nativeElement.value = this.count;
      this._cdRef.detectChanges();

      if (this.count.toFixed(0) !== previousAccountValue) {
        this.onChange.emit(this.count);
      }
    }
  }
}
