import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { TextInputComponent } from "./shared/atoms/text-input/text-input.component";
import { DateInputComponent } from "./shared/atoms/date-input/date-input.component";
import { DropdownComponent } from "./shared/atoms/dropdown/dropdown.component";
import { TreeViewComponent } from "./shared/atoms/tree-view/tree-view.component";
import { CounterComponent } from "./shared/atoms/counter/counter.component";
import { LoadingComponent } from "./shared/atoms/loading/loading.component";

import { RoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BookingComponent } from "./booking/booking.component";
import { ResourcesService } from "./shared/api/resources/resources.service";
import { SettingsService } from "./shared/api/resources/settings/settings.service";
import { SummaryComponent } from "./summary/summary.component";
import { PaymentComponent } from "./payment/payment.component";
import { NgxStripeModule } from "ngx-stripe";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { GlobalService } from "./shared/global.service";
import { WidgetService } from "./shared/api/widget/widget.service";
import { ToastrModule } from "ngx-toastr";
import { ToasterService } from "./shared/atoms/toaster/toaster.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FocusDirective } from "./shared/utils/focus.directive";
import { LoadingSmallComponent } from "./shared/atoms/loading-small/loading-small.component";
import { PaxComponent } from "./pax/pax.component";
import { AddComponent } from "./add/add.component";
import { ErrorComponent } from "./error/error.component";
import { TransportComponent } from "./transport/transport.component";
import { TermsComponent } from "./terms/terms.component";
import { QuestionComponent } from "./question/question.component";
import { CartService } from "./shared/cart/cart.service";
import { CartComponent } from "./cart/cart.component";
// Analytics
import { GoogleAnalyticsService } from "./google-analytics.service";
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from "../environments/environment";
import { FilterByComponentTypePipe } from "./shared/filter-by-component-type.pipe";

// Icons
import { NgIconsModule } from '@ng-icons/core';
import { bootstrapCreditCardFill } from '@ng-icons/bootstrap-icons';

@NgModule({ 
    declarations: [
        TextInputComponent,
        DateInputComponent,
        DropdownComponent,
        TreeViewComponent,
        CounterComponent,
        LoadingComponent,
        LoadingSmallComponent,
        AppComponent,
        BookingComponent,
        SummaryComponent,
        PaymentComponent,
        ThankyouComponent,
        FocusDirective,
        PaxComponent,
        AddComponent,
        ErrorComponent,
        TransportComponent,
        TermsComponent,
        QuestionComponent,
        CartComponent,
        FilterByComponentTypePipe,
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RoutingModule,
        NgbModule,
        FormsModule,
        NgxStripeModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: "toast-top-center",
        }),
        GoogleTagManagerModule.forRoot(),
        NgIconsModule.withIcons({ bootstrapCreditCardFill }),
    ], 
    providers: [
        ResourcesService,
        SettingsService,
        GlobalService,
        WidgetService,
        ToasterService,
        CartService,
        GoogleAnalyticsService,
        {
            provide: "googleTagManagerId",
            useValue: environment.gtagId /*YOUR_GTM_ID*/,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {}
