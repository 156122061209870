import { Injectable } from '@angular/core';
import {Product} from './api/model/product';
import {Customer} from './api/model/customer';
import {GenderPrice} from './api/model/gender-price';
import {Booking} from './api/model/booking';
import {BookingCalculation} from './api/model/booking-calculation';
import {Event} from './api/model/event';
import {RouteItinerary} from './api/model/route-itinerary';
import { EventComponent } from './api/model/event-component';
import { StaticUtils } from './utils/static-utils';

@Injectable()
export class GlobalService {
  private static staticEvent: Event | null = null;
  loading = false;
  loadingTransportCalculation = false;
  processingPayment = false;
  guid: string;
  hide_date = false;
  event_date: any;
  skip_my_cart = false;
  productId: number;
  product: Product;
  event: Event;
  productGenderPrices = [];
  productPaxPrices = [];
  eventGenderPrices: GenderPrice[];
  customer: Customer;
  booking: Booking;
  confirmEmail: string;
  selectedBookingTransportPickupItenerary: RouteItinerary;
  selectedBookingTransportDropoffItenerary: RouteItinerary;
  tguid: string;
  errorMessage: string;
  calculatedPrice: BookingCalculation;
  userWantsTransport = false;
  acceptedTerms = false;
  selectedTransportComponent: EventComponent;
  isMoreThanOneTransportComponent = false;

  constructor() {}

  clean() {
    this.clearBookingProductAndEvent();
    this.customer = undefined;
    this.errorMessage = undefined;
    this.acceptedTerms = false;
    this.confirmEmail = undefined;
  }

  clearBookingProductAndEvent() {
    this.productId = undefined;
    this.product = undefined;
    this.event = undefined;
    this.productGenderPrices = [];
    this.productPaxPrices = [];
    this.eventGenderPrices = undefined;
    this.booking = undefined;
    this.calculatedPrice = undefined;
    this.selectedTransportComponent = undefined;
  }

  setStaticEvent(event: Event) {
    if (!GlobalService.staticEvent) {
      GlobalService.staticEvent = event;
    }
  }
  getStaticEvent(): Event | null {
    return GlobalService.staticEvent;
  }
  bookingHasPax() {
    return this.booking && this.booking.bookingGenderPrices.some(genderPrice => genderPrice.quantityToBook > 0);
  }

  hasAccessExtras(): boolean {
    return this.event && this.event.widgetHaveAccessExtras;
  }

  hasAccessTransport(): boolean {
    return this.event && this.event.widgetHaveAccessExtras;
  }

  hasExtras(): boolean {
    return this.event && this.event.product && this.event.product.extras && this.event.product.extras.length > 0;
  }

  hasTransport(): boolean {
    return this.event && this.event.eventRoutes && this.event.eventRoutes.length > 0;
  }

  totalPax(): number {
    let totalPax = 0;

    for (let personPrice of this.event.eventGenderPrices) {
      // If is price per person (genderPrice = 2)
      if (personPrice.genderPriceType == 2) {
        // let quantityPerTicket = audience.occupancy? audience.occupancy : 1 // for example: "Group of 3" will have 3, else just use 1
        let quantityPerTicket = personPrice.audienceTypeOccupancy
          ? personPrice.audienceTypeOccupancy
          : 1;
        totalPax += personPrice.quantityToBook * Number(quantityPerTicket);
      } else {
        // is price per order WITH participants
        if (
          personPrice.genderPriceType == 1 &&
          this.event.eventGenderPrices.length > 1
        ) {
          totalPax +=
            personPrice.audienceType != null ? personPrice.quantityToBook : 0;
        }

        // is price per order WITHOUT participants
        if (
          personPrice.genderPriceType == 1 &&
          this.event.eventGenderPrices.length == 1
        ) {
          totalPax += 1;
        }
      }
    }

    return totalPax;
  }

  get totalPrice(): string {
    return StaticUtils.convertValueToCurrency(this.calculatedPrice ? this.calculatedPrice.grandTotal : 0);
  }
}
