import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ToasterService {

  constructor(private _toastr: ToastrService) { }

  showError(msg:any) {
    let errorMsg = ''

    // show server error message if exists
    if(msg && msg.error.serverInfo.isToShowServerInfo === true) errorMsg = msg.error.serverInfo.message
    else errorMsg = 'Something went wrong. Please try again later.'

    this._toastr.error(errorMsg, 'Oops!');
  }

  showWarning(msg: string) {
    this._toastr.warning(msg, 'Alert!');
  }

  showInfo(msg: string) {
    this._toastr.info(msg);
  }
}
