import {Directive, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements OnInit{
  @Input('focus') focusEvent: EventEmitter<boolean>;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.focusEvent.subscribe(() => {
      setTimeout(() => {
        this.element.nativeElement.focus();
      }, 0);
    });
  }

}
