<div class="event-title">
  <h4>{{event.product.name}}</h4>
  <p>{{utils.formatDateTime(moment(event.start))}} ({{utils.formatDuration(moment(moment().format('YYYY-MM-DD') + ' ' + event.duration))}})</p>
  <h5 *ngIf="_globalService.selectedTransportComponent" class="mt-3 mb-1">
    {{ _globalService.selectedTransportComponent ? _globalService.selectedTransportComponent.component.name : '' }} - {{ _globalService.selectedTransportComponent ? _globalService.selectedTransportComponent.componentPrice.name : '' }}
  </h5>
  <span *ngIf="_globalService.selectedTransportComponent">
    Price per passenger: {{utils.convertValueToCurrency(_globalService.selectedTransportComponent.componentPrice.grossprice)}}
    <br>
    Total price: {{ utils.convertValueToCurrency( _globalService.totalPax() * _globalService.selectedTransportComponent.componentPrice.grossprice ) }}
  </span>
</div>

<div *ngIf="!foundPickupOrDropoff(); else pickupAndDropoffAccordion" class="custom-address">
  <div class="selection-container">
    <!-- custom address -->
    <h3>Address</h3>
    <app-text-input [loading]="false"
                    [placeholder]="'Insert your location'"
                    [value]="customPickupFrom"
                    (onChange)="customPickupFrom = $event">
    </app-text-input>
    <span class="selected-itinerary-notes"
          *ngIf="event && event.eventRouteCustomInfo && event.eventRouteCustomInfo !== ''">
          Notes: {{event.eventRouteCustomInfo}}
    </span>
  </div>
</div>

<ng-template #pickupAndDropoffAccordion>
  <div class="card-body">
    <div *ngIf="(!_globalService.isMoreThanOneTransportComponent && pickupItenerariesList.length !== 0)" class="panel-header">
      <h3>{{ pickupItenerariesList.length === 0 ? 'Address' : 'Pickup at'}}</h3>
      <span class="custom-address-toggle"
        (click)="togglePickupCustomAddress()">
        {{pickupCustomAddressToggle ? 'Pickup List' : 'Add custom address'}}
      </span>
    </div>

    <div class="panel-body">
      <div class="selection-container">
        <div class="route-itinerary-selection-container">
          <!-- adress from pickup list -->
          <span *ngIf="pickupItenerariesList && pickupItenerariesList.length > 0">
            <app-dropdown [placeholder]="selectedPickupItenerary ? selectedPickupItenerary.stop.name : 'Select a pickup point'"
                          [options]="pickupItenerariesList"
                          (onChange)="selectedPickupItenerary = $event.value"
                          *ngIf="!pickupCustomAddressToggle">
            </app-dropdown>
            <span class="selected-itinerary-notes"
                  *ngIf="!pickupCustomAddressToggle && selectedPickupItenerary && selectedPickupItenerary.note && selectedPickupItenerary.note !== ''">
              Notes: {{selectedPickupItenerary.note}}
            </span>
          </span>
          <!-- custom pickup address -->
          <span *ngIf="isToShowCustomPickupAddress()">
            <span *ngIf="isToShowPickupAtText() || !_globalService.isMoreThanOneTransportComponent && pickupItenerariesList.length === 0" class="panel-header">
              Pickup at
            </span>
            <app-text-input [loading]="false"
                            [placeholder]="'Insert your location'"
                            [class]="'mb-2'"
                            [value]="customPickupFrom"
                            (onChange)="customPickupFrom = $event">
            </app-text-input>
            <span class="selected-itinerary-notes"
                  *ngIf="pickupCustomAddressToggle && event && event.eventRouteCustomInfo && event.eventRouteCustomInfo !== '' || !_globalService.isMoreThanOneTransportComponent && pickupItenerariesList.length === 0">
              Notes: {{event.eventRouteCustomInfo}}
            </span>
          </span>
        </div>
        <div class="route-schedule-selection-container" *ngIf="pickupSchedulesList && selectedPickupItenerary && selectedPickupSchedule">
          <button [ngClass]="selectedPickupSchedule.id === schedule.value.id ? 'btn active' : 'btn'"
                  *ngFor="let schedule of pickupSchedulesList"
                  (click)="selectedPickupSchedule = schedule.value">
            {{schedule.label}}
          </button>
        </div>
        <span class="route-destination" *ngIf="!pickupCustomAddressToggle && selectedPickupRoute">
          To <b>{{selectedPickupRoute?.routeDestination?.name}}</b>
        </span>
      </div>
    </div>
  </div>  

  <div class="line"></div>

  <div class="card-body">
    <div *ngIf="!_globalService.isMoreThanOneTransportComponent && dropoffItenerariesList.length !== 0" class="panel-header">
      <h3>{{ dropoffItenerariesList.length === 0 ? 'Address' : 'Return to'}}</h3>
      <span class="custom-address-toggle"
        (click)="toggleDropoffCustomAddress()">
        {{dropoffCustomAddressToggle ? 'Pickup List' : 'Add custom address'}}
      </span>
    </div>

    <div class="panel-body">
      <div class="selection-container">
        <div class="route-itinerary-selection-container justify-content-end">
          <!-- adress from dropoff list -->
          <span *ngIf="dropoffItenerariesList && dropoffItenerariesList.length > 0">
            <app-dropdown [placeholder]="selectedDropoffItenerary ? selectedDropoffItenerary.stop.name : 'Select a dropoff point'"
                          [options]="dropoffItenerariesList"
                          (onChange)="selectedDropoffItenerary = $event.value"
                          *ngIf="!dropoffCustomAddressToggle">
            </app-dropdown>
            <span class="selected-itinerary-notes"
                  *ngIf="!dropoffCustomAddressToggle && selectedDropoffItenerary && selectedDropoffItenerary.note && selectedDropoffItenerary.note !== ''">
              Notes: {{selectedDropoffItenerary.note}}
            </span>
          </span>
          <!-- custom dropoff address -->
          <span *ngIf="isToShowCustomReturnAddress()">
            <span *ngIf="isToShowReturnToText() || !_globalService.isMoreThanOneTransportComponent && dropoffItenerariesList.length === 0" class="panel-header">
              Return to
            </span>
            <app-text-input [loading]="false"
                            [placeholder]="'Insert your location'"
                            [class]="'mb-2'"
                            [value]="customDropoffTo"
                            (onChange)="customDropoffTo = $event">
            </app-text-input>
            <span class="selected-itinerary-notes"
                  *ngIf="dropoffCustomAddressToggle && event && event.eventRouteCustomInfo && event.eventRouteCustomInfo !== '' || !_globalService.isMoreThanOneTransportComponent && dropoffItenerariesList.length === 0">
              Notes: {{event.eventRouteCustomInfo}}
            </span>
        </span>
        </div>
        <span class="route-destination" *ngIf="!dropoffCustomAddressToggle && selectedDropoffItenerary && getDropoffDestinationItinerary">
          Select time to start return trip at<br>
          <b>{{getDropoffDestinationItinerary()?.stop?.name}}</b>
        </span>
        <div class="route-schedule-selection-container" *ngIf="dropoffSchedulesList && selectedDropoffItenerary && !dropoffCustomAddressToggle">
          <button [ngClass]="selectedDropoffSchedule.id === schedule.value.id ? 'btn active' : 'btn'"
                  *ngFor="let schedule of dropoffSchedulesList"
                  (click)="selectedDropoffSchedule = schedule.value">
            {{schedule.label}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="total-price">
  <app-loading *ngIf="_globalService.loadingTransportCalculation"></app-loading>
  <p *ngIf="!_globalService.loadingTransportCalculation">Total</p>
  <p *ngIf="!_globalService.loadingTransportCalculation">{{ _globalService.totalPrice }}</p>
</div>
<div class="submit-container">
  <button class="btn" (click)="goToSummaryOrQuestion()" [disabled]="false">
    {{event && event.bookingQuestionIsActive ? 'Next' : 'Go to Summary'}}
  </button>
</div>
