<div class="summary-details">
  <div class="summary-details-item" *ngFor="let booking of cart?.bookings">
    <div class="summary-details-header">
      <h2>{{booking.event.product.name}} <span class="promocode-mark" *ngIf="booking.attachPromocode === currentPromocode"></span></h2>
      <span>
        <p class="warning-pending" *ngIf="showMessagePendingConfirm(booking)">This booking will remain pending confirmation. Please wait for confirmation by email.</p>
      </span>
      <h3 *ngIf="booking.event.product.transport">{{booking.event.product.transport.name}}</h3>
      <p class="departure" *ngIf="!isToHideDate()">Departure date: {{utils.formatDateTime(moment(booking.event.start))}}</p>
      <p class="duration" *ngIf="!isToHideDate()">Duration: {{utils.formatDuration(moment(moment().format('YYYY-MM-DD') + ' ' + booking.event.duration))}}</p>
    </div>
    <div class="summary-details-table">
      <div class="item" *ngFor="let genderPrice of booking.bookingGenderPrices">
        <span *ngIf="genderPrice.quantityToBook > 0">
          {{genderPrice.quantityToBook}} x
          {{genderPrice.audienceTypeDescription ? genderPrice.audienceTypeDescription : 'Tickets'}}
        </span>
      </div>
      <div class="item" *ngFor="let component of booking.bookingComponentsGrouped">
        <span>{{component.quantity}} x {{component.component.name}} - {{component.componentPrice.name}}</span>
      </div>
      <div class="item" *ngFor="let transfer of booking?.transport?.transfers">
        <span>{{transfer.transportDirectionDescription}} - {{utils.formatDateTime(moment(transfer.date))}} - {{transfer.stop.name}}</span>
      </div>
      <div style="margin-top: 26px;">
        <span class="summary-details-item-amount">{{utils.convertValueToCurrency(booking.billingTotal)}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!isToHidePromocode" class="summary-details-promocode">
    <div class="promocode-toggle"
         (click)="togglePromocodesCollapse()">
      <!-- <div class="icon-container">
        <span class="ico-add" *ngIf="isPromocodesCollapsed"></span>
        <span class="ico-minus" *ngIf="!isPromocodesCollapsed"></span>
      </div> -->
      <label style="margin-left: 0;">Promocodes</label>
    </div>
    <div class="promocode-input"
         [ngStyle]="collapseStyles"
         *ngIf="!currentPromocode">
      <div class="input-container" *ngIf="!currentPromocode">
        <app-text-input [loading]="findingPromocode"
                        [placeholder]="'Ex: PROMO10'"
                        [value]="promocode"
                        (onChange)="promocode = $event">
        </app-text-input>
        <button class="btn btn-primary" (click)="applyPromocode()">Apply</button>
      </div>
    </div>
    <div class="promocode-error" [title]="getPromocodeErrorMessage()" *ngIf="promocodeNotValid">
      <span>{{getPromocodeErrorMessage()}}</span>
    </div>
    <span class="promocode" *ngIf="currentPromocode">
       <span class="promocode-mark"></span>
      {{currentPromocode}}
      <span class="ico-close" (click)="removePromocodeFromCart()"></span>
    </span>
  </div>
  <div class="summary-details-footer">
    <span>Total cost</span>
    <span>{{utils.convertValueToCurrency(cart ? cart.billingTotal : 0)}}</span>
  </div>
</div>
<form class="booking-form" autocomplete="off" (ngSubmit)="book().then()" *ngIf="customer" #bookingForm="ngForm">
  <div class="input-container name-input">
    <label>Full Name <span>*</span></label>
    <div class="input-group">
      <input class="form-control" type="text" autocomplete="off" [(ngModel)]="customer.name" [ngModelOptions]="{standalone: true}">
    </div>
  </div>
  <div class="input-container phone-input">
    <label>Phone <span>*</span></label>
    <div class="input-group">
      <input
        class="form-control" type="phone" autocomplete="off"
        [(ngModel)]="customer.phone"
        [ngModelOptions]="{standalone: true}"
        (blur)="validateCustomerPhone()"
      >
      <small
        class="alert-danger"
        *ngIf="!phoneValid"
      >Please insert a valid phone number</small>
    </div>
  </div>
  <div class="input-container country-input">
    <label>Country <span>*</span></label>
    <div class="input-group">
      <app-dropdown [options]="nationalities" [placeholder]="selectedCountry" color="white" [enableSearch]="true" (onChange)="customer.country = $event.value.id"></app-dropdown>
    </div>
  </div>
  <div class="input-container email-input">
    <label>Email <span>*</span></label>
    <div class="input-group">
      <input class="form-control" type="email" autocomplete="off" [(ngModel)]="customer.email" [ngModelOptions]="{standalone: true}" (blur)="validateCustomerEmail()">
      <small class="alert-danger" *ngIf="!emailValid">Please insert a valid email</small>
    </div>
  </div>
  <div class="input-container confirm-email-input">
    <label>Confirm your email <span>*</span></label>
    <div class="input-group">
      <input class="form-control" type="email"  autocomplete="off" [(ngModel)]="confirmEmail" [ngModelOptions]="{standalone: true}" (blur)="matchEmails(); validateCustomerEmail()">
      <small class="alert-danger" *ngIf="!emailsMatch">Emails do not match</small>
    </div>
  </div>
  <div class="input-container notes-input">
    <label>Notes</label>
    <div class="input-group">
      <textarea class="form-control"  autocomplete="off" [(ngModel)]="customer.specialAssistanceNote" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>

  <div class="billing-form">
    <div class="billing-toggle" (click)="toggleBillingCollapse()">
      <div class="icon-container">
        <span class="ico-add" *ngIf="isBillingCollapsed"></span>
        <span class="ico-minus" *ngIf="!isBillingCollapsed"></span>
      </div>
      <label *ngIf="!isBillingCollapsed">Billing Details</label>
      <label *ngIf="isBillingCollapsed">Add Billing Details</label>
    </div>
    <div class="billing-details" *ngIf="billingDetails" [ngbCollapse]="isBillingCollapsed" [ngStyle]="billingCollapseStyles">
      <div class="input-container vat-input">
        <label>VAT Number</label>
        <div class="input-group">
          <input class="form-control" type="text" autocomplete="off" [(ngModel)]="billingDetails.vat" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="input-container country-input">
        <label>Country</label>
        <div class="input-group">
          <app-dropdown [options]="nationalities" [enableSearch]="true" (onChange)="billingDetails.country = $event.value.id"></app-dropdown>
        </div>
      </div>
      <div class="input-container name-input">
        <label>Billing Name</label>
        <div class="input-group">
          <input class="form-control" type="text" autocomplete="off" [(ngModel)]="billingDetails.businessName" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="input-container address-input">
        <label>Address</label>
        <div class="input-group">
          <input class="form-control" type="text" autocomplete="off" [(ngModel)]="billingDetails.address" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
  </div>

  <div class="input-container terms-input">
    <label>
      <input type="checkbox" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}">
      I accept <a routerLink="/terms" queryParamsHandling="merge" (click)="saveCustomer()">the terms and cancellation conditions</a>
      <span>*</span>
    </label>
  </div>

  <div class="input-container submit-btn">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="isLoading || !customer.name || !customer.phone || !customer.country || !customer.email
      || confirmEmail.toLowerCase() !== customer.email.toLowerCase() || !termsAccepted || !validEmail(customer.email) || !validPhone(customer.phone)">
      Book Now
    </button>
  </div>
</form>
