import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../shared/global.service';
import { WidgetService } from '../shared/api/widget/widget.service';
import { ToasterService } from '../shared/atoms/toaster/toaster.service';
import { CartService } from '../shared/cart/cart.service';
import { BookingShoppingCart } from '../shared/api/model/BookingShoppingCart';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { StaticUtils } from '../shared/utils/static-utils';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  creatingReservation: boolean = false;
  cart: BookingShoppingCart;
  elements: any;
  paymentElement: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService,
    private _stripeService: StripeService,
    private _widgetService: WidgetService,
    private _cartService: CartService,
    private _toasterService: ToasterService,
    private gtmService: GoogleTagManagerService
  ) {}

  async ngOnInit() {
    this._globalService.loading = true;
    if (!this._stripeService.stripe.getInstance()) {
      await new Promise((resolve) => {
        this._widgetService.getStripeKey().subscribe(
          (data) => {
            this._stripeService.setKey(data.stripeApiKey);
            resolve(true);
          },
          (error) => {
            this._toasterService.showError(error.error.message);
            resolve(false);
          }
        );
      });
    }
    this.loadCartAndInitPaymentRequest();
  }
  
  loadCartAndInitPaymentRequest() {
    setTimeout(async () => {
      this.cart = await this._cartService.getCart();
      this.initExpressCheckout(); 
    });
  }

  initExpressCheckout() {
    const stripe = this._stripeService.stripe.getInstance();
    const totalAmount = Math.round(this.cart.billingTotal * 100);

    const options = {
      paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      },
    };
    
    this.elements = stripe.elements({
      mode: 'payment',
      amount: totalAmount,
      currency: 'eur',
      locale: 'en',
      paymentMethodTypes: ['card']
    });
    
    this.paymentElement = this.elements.create("payment", options);
    this.paymentElement.mount("#payment-element");

    this._globalService.loading = false;
  }

  async handleSubmit(event: any) {
    event.preventDefault();
    let intentResult: any;
    const stripe = this._stripeService.stripe.getInstance();
    const guid = this._route.snapshot.queryParamMap.get('guid');
    
    this._globalService.loading = true;
    this.creatingReservation = true;
    this._globalService.processingPayment = true;

    if (!stripe || !this.elements) {
      this._globalService.errorMessage = 'Something went wrong with your connection. Please check your internet and try again.';

      this._router.navigate(['../error/'], { 
        queryParams: { 
          goHome: true, 
        },
        queryParamsHandling: 'merge' 
      });

      return;
    } 
      
    const { error: submitError } = await this.elements.submit();
    
    if (submitError) {
      this._globalService.loading = false;
      this.creatingReservation = false;
      this._globalService.processingPayment = false;

      if (submitError.code === 'incomplete' && submitError.type === 'validation_error') {
        this._globalService.errorMessage = 'Something unexpected happened. Please try again';

        this._router.navigate(['../error/'], { queryParamsHandling: 'merge' });
      }
      return;
    } 

    intentResult = await this._widgetService.shoppingCartStripePaymentIntent(this.cart.id, this.cart.guid).toPromise();
    const clientSecret = intentResult.clientSecret;
    const shoppingCartId = intentResult.shoppingCartId;
    const shoppingCartGuid = intentResult.shoppingCartGuid;
    this._globalService.booking = intentResult.bookingShoppingCart;

    const result = await stripe.confirmPayment({
      elements: this.elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/thankyou?guid=${guid}&scid=${shoppingCartId}&scguid=${shoppingCartGuid}`,
      },
      redirect: 'if_required',
    });
  
    if (result.error) {
      this.handleStripeErrors(result);
    } else if (result && result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      this._globalService.loading = false;
      this.creatingReservation = false;
      this._globalService.processingPayment = false;

      this.cart = await this._cartService.getCart();
      this._createAnalyticsEvent(this.cart);

      await this._router.navigate(['../thankyou/'], { queryParamsHandling: 'merge' });
    }
  }

  /**
  * Handles Stripe errors based on specific conditions to determine if the application should redirect to an error page.
  *
  * - payment_intent_authentication_failure: Occurs when an authentication step fails (e.g., 3D Secure authentication).
  * - processing_error: Indicates an error occurred during the processing of the payment.
  * - card_decline & card_error without shouldRetry: When a card is declined and the error type is card_error, but there's no option to retry.
  * 
  * If any of these conditions are met, the user is redirected to the '../error/' page for further resolution. 
  * Otherwise, the error is considered handled by the Payment Element component itself.
  */
  handleStripeErrors(result: any): void {
    const error = result?.error;
    this._globalService.loading = false;
    this._globalService.processingPayment = false;
    this.creatingReservation = false;
    
    if ( error?.code === 'payment_intent_authentication_failure' ||
      error?.code === 'processing_error' ||
      (error?.code === 'card_declined' && error?.type === 'card_error' && !error?.shouldRetry) ||
      error?.type === 'invalid_request_error'
    ) {
      this._globalService.errorMessage = error.message;
      
      this._router.navigate(['../error/'], { queryParamsHandling: 'merge' });
    }
  }

  private _createAnalyticsEvent(cart: object): void {
    const products = [];

    cart['bookings'].forEach(booking => {
      products.push({
        'name': booking.event.product.name,
        'id': booking.event.product.id.toString(),
        'quantity': booking.paxTotal,
        'price': booking.billingTotal,
        'coupon': booking.attachPromocode,
      });
    });

    const gtmTag = {
      event: 'purchase',
      ecommerce: {
        'currencyCode': 'EUR',
        'purchase': {
          'actionField': {
            'id': cart['id'],
            'affiliation': 'Pluralo Widget',
            'revenue': cart['billingTotal'],
            'coupon': cart['attachPromocode'],
          },
          products
        }
      }
    };

    this.gtmService.pushTag(gtmTag);
  }

  formatErrorMessage(message: string): string {
    return message.replace(/\n/g, '<br>');
  }

  get totalPrice(): string {
    return StaticUtils.convertValueToCurrency(this.cart && this.cart.billingTotal ? this.cart.billingTotal : 0);
  }
}
