import {Event} from './event';
import {Customer} from './customer';
import {Note} from './note';
import {GenderPrice} from './gender-price';
import {Transport} from './transport';
import {EventComponent} from './event-component';

export class Booking {
  id: number;
  attachCommission: number;
  attachDiscount: number;
  attachVoucher: number;
  calculationsGUID: string;
  childBookingId: number;
  creatorUserAccount: any;
  creatorAgent: any;
  creatorSupplier: any;
  reservationDate: string;
  eventDate: string;
  paxCheckedIn: number;
  paxNoShow: number;
  paxTotal: number;
  currency: '€';
  billingTotal: number;
  billingPaid: number;
  billingInDue: number;
  status: number;
  statusDescription: string;
  dealType: any;
  customer: Customer;
  bookingGenderPrices: GenderPrice[];
  event: Event;
  hasTransport: false;
  transport: Transport;
  notes: Note[];
  billing: any;
  billingList: any[];
  parentBookingId: number;
  bookingChilds: any[];
  bookingCheckins: any[];
  bookingComponents: EventComponent[];
  bookingComponentsGrouped: EventComponent[];
  discountValueType: any;
  discountValue: any;
  depositType: any;
  depositValue: any;
  depositCurrency: any;
  bookingAgentPaymentStatus: number;
  paymentStatus: number;
  paymentStatusDescription: string;
  attachPromocode: string;
  bookingShoppingCartId: number;
  bookingQuestion: string;
  bookingAnswer: string;
}
