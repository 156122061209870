<div
  ngbDropdown
  class="d-flex"
  [autoClose]="'outside'"
  (openChange)="open = !open"
  #dropdown="ngbDropdown"
>
  <button
    type="button"
    class="btn"
    [ngStyle]="styles"
    [disabled]="isOptionsDisabled()"
    ngbDropdownToggle
    #toggle
  >
    <span *ngIf="!enableSearch || (enableSearch && !open)">{{
      placeholder
    }}</span>
    <span *ngIf="options && options.length > 1" class="ico-arrow-bottom"></span>
  </button>
  <input
    type="text"
    class="search"
    autocomplete="off"
    #input
    appFocus
    [focus]="searchFocusEvent"
    [ngClass]="enableSearch && open ? 'visible' : ''"
    [(ngModel)]="search"
    (click)="$event.stopPropagation()"
  />
  <div ngbDropdownMenu #menu>
    <div class="tree-view">
      <div
        *ngIf="filteredOptions && filteredOptions.length > 0"
        class="dropdown-box"
      >
        <ul>
          <li *ngFor="let option of filteredOptions">
            <ng-container *ngIf="option.type === 'category'; else onlyProduct">
              <button
                type="button"
                class="btn-no-border"
                [ngStyle]="styles"
                [disabled]="option.children && option.children.length <= 0"
                (click)="toggleDropdown(option); $event.stopPropagation()"
              >
                <span
                  >{{ option.isExpanded ? "-" : "+" }} {{ option.label }}</span
                >
              </button>
              <ul *ngIf="option.isExpanded">
                <li
                  *ngFor="let child of option.children"
                  class="children-label"
                  (click)="setValue(child, dropdown); $event.stopPropagation()"
                >
                  <span>{{ child.label }}</span>
                </li>
              </ul>
            </ng-container>
            <ng-template #onlyProduct>
              <li
                class="children-label"
                (click)="setValue(option, dropdown); $event.stopPropagation()"
              >
                <span>{{ option.label }}</span>
              </li>
            </ng-template>
          </li>
        </ul>
      </div>
      <p *ngIf="!filteredOptions || filteredOptions.length === 0">
        No options to show
      </p>
    </div>
  </div>
</div>
