import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourcesService } from '../resources.service';
import {AudienceType} from '../../model/audience-type';

@Injectable()
export class SettingsService {
  countries: Array<any>;
  accountTypes: Array<any>;

  constructor(private _resources: ResourcesService) { }

  getAllRequests(): Observable<Array<any>> {
    return new Observable((observer) => {
      const isComplete = () => {
        return this.countries !== undefined &&
          this.accountTypes !== undefined;
      };

      this.getCountryList().subscribe(
         (countries: Array<any>) => {
           this.countries = countries;
           observer.next(this.countries);
           if (isComplete()) {
             observer.complete();
           }
         },
        () => {
          this.countries = [];
          observer.next(this.countries);
          if (isComplete()) {
            observer.complete();
          }
        }
      );

      this.getAccountTypeList().subscribe(
        (accounts: Array<any>) => {
          this.accountTypes = accounts;
          observer.next(this.accountTypes);
          if (isComplete()) {
            observer.complete();
          }
        },
        () => {
          this.accountTypes = [];
          observer.next(this.accountTypes);
          if (isComplete()) {
            observer.complete();
          }
        }
      );
    });
  }

  getAccountTypeList(): Observable<any[]> {

    return this._resources.get('settings/GetAccountTypeList');

  }

  getCountryList(): Observable<any[]> {

    return this._resources.get('country/list');

  }

}
