import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../shared/global.service';
import {Router} from '@angular/router';
import { CartService } from '../shared/cart/cart.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  supplierName: string;
  productName: string;
  supplierText: string;

  constructor(private _router: Router,
              private _globalService: GlobalService,
              private _cartService: CartService) {
  }

  public ngOnInit(): void {
    // this.supplierName = this._globalService.event.supplierName;
    // this.productName = this._globalService.event.product.name;
    // this.supplierText = this._globalService.event.product.descriptionMore;
  }

  public async accept(): Promise<void> {
    this._globalService.acceptedTerms = true;
    await this._router.navigate(['summary'], { queryParamsHandling: 'merge' });
  }

  get bookings() {
    return this._cartService.getCartBookings();
  }
}
