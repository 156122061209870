export class Location {
  id: number;
  imagePhoto: string;
  imagePhotoBase64: string;
  address: string;
  latitude: string;
  locationType: number;
  longitude: string;
  name: string;
  notes: string;
}
